import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from '../functions/withRouter';
import { useNavigate } from 'react-router-dom';
import { TreeView, processTreeViewItems } from '@progress/kendo-react-treeview';
import { useIsAuthenticated } from '@azure/msal-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import { Header } from './Header';
import { SignInButton } from './SignInButton';

const tree = [
  { text: 'Home', route: '/' },
  { text: 'Notification Settings', route: '/notification-settings' },
  {
    text: 'Requisitions - New',
    items: [
      { text: 'New from Scratch', route: '/new-scratch' },
      { text: 'New from Clone Existing', route: '/new-clone' }
      // { text: 'New from Import Template', route: '/new-template' }
    ]
  },
  { text: 'Requisitions - My Projects', route: '/req-mine' },
  { text: 'Outstanding Reviews - My Projects', route: '/req-outstanding' },
  { text: 'Requisitions - For Review', route: '/req-review' },
  { text: 'Requisitions - Lines', route: '/req-lines' },
  { text: 'Requisitions - All', route: '/req-all' },
  {
    text: 'Administration / Configuration',
    items: [
      {
        text: 'System Admin',
        items: [
          { text: 'Users', route: '/users' },
          { text: 'Line Purposes', route: '/line-purposes' },
          { text: 'Line Purpose Constraints', route: '/line-purpose-constraints' }
        ]
      },
      {
        text: 'Project Admin',
        items: [
          { text: 'Projects', route: '/projects' },
          { text: 'Projects & Jobs', route: '/projects-jobs' },
          { text: 'Projects & Categories', route: '/projects-categories' },
          { text: 'Projects & Delivery Locations', route: '/projects-delivery-locations' },
          { text: 'Projects & Commodity Codes', route: '/commodity-codes' },
          { text: 'Projects, Jobs, Categories, & Codes', route: '/projects-jobs-categories-codes' },
          { text: 'Projects, Users, Roles, & Notifications', route: '/projects-users-roles-notifications' },
          { text: 'Projects, Categories, & Reviewers', route: '/projects-categories-reviewers' }
        ]
      },
      {
        text: 'Workflow',
        items: [{ text: 'Projects & Shared Mailboxes', route: '/projects-shared-mailboxes' }]
      },
      {
        text: 'Reference',
        items: [
          { text: 'UoM (Unit of Measure)', route: '/uom' },
          { text: 'Reason for Purchase', route: '/reason-for-purchase' },
          { text: 'Pressure Retaining Material Notes', route: '/pressure-retaining-material-notes' }
        ]
      }
    ]
  }
];

function DRC(props) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [expand, setExpand] = React.useState({
    ids: [],
    idField: 'text'
  });
  const [select, setSelect] = React.useState(['']);
  const [currentPage, setCurrentPage] = React.useState('');
  const isAuthenticated = useIsAuthenticated();

  const toggleVisible = (e) => {
    setVisible(!visible);
  };

  const handleSelect = (e) => {
    if (e.item.items) {
      // submenu
      let ids = expand.ids.slice();
      const index = ids.indexOf(e.item.text);
      index === -1 ? ids.push(e.item.text) : ids.splice(index, 1);
      setExpand({
        ids,
        idField: 'text'
      });
    } else if (e.item.route.includes('http')) {
      // external link
      window.open(e.item.route, '_blank').focus();
    } else {
      // route
      setSelect([e.itemHierarchicalIndex]);
      setCurrentPage(e.item.text);
      toggleVisible();
      navigate(e.item.route);
    }
  };

  const searchTree = useCallback((element, matchingRoute) => {
    if (element.route === matchingRoute) {
      return element;
    } else if (element.length > 0) {
      let result = null;
      for (const item of element) {
        result = searchTree(item, matchingRoute);
        if (result !== null) break;
      }
      return result;
    } else if (element.items != null) {
      let result = null;
      for (const item of element.items) {
        result = searchTree(item, matchingRoute);
        if (result !== null) break;
      }
      return result;
    }

    return null;
  }, []);

  // find and set 'currentPage' for use in tab title
  useEffect(() => {
    if (currentPage === '') {
      let route = window.location.pathname;

      if (Array.from(route).filter((char) => char === '/').length > 1)
        route = route.substring(0, route.indexOf('/', 1));

      if (route === '/details') {
        setCurrentPage('Details - ' + window.location.pathname.substring(window.location.pathname.indexOf('/', 1) + 1));
      } else {
        setCurrentPage(searchTree(tree, route).text);
      }
    }
  }, [currentPage, searchTree]);

  // useEffect(() => {
  //     document.querySelectorAll("[route=\"" + location.pathname + "\"]")[0].parentNode.parentNode.click();
  // }, [location.pathname]);

  const NavItem = (props) => {
    return <span route={props.item.route}>{props.item.text}</span>;
  };

  return (
    <React.Fragment>
      <ReactTooltip />
      <Header onButtonClick={toggleVisible} currentPage={currentPage} />
      <ToastContainer position='bottom-right' />
      <div className={`k-drawer-container k-drawer-push ${visible ? 'k-drawer-expanded' : ''}`}>
        <div
          className='k-widget k-drawer k-drawer-start nav-menu'
          style={visible ? { opacity: 1 } : { flexBasis: '0px' }}
        >
          <div className='k-drawer-wrapper' style={{ padding: '8px' }}>
            <TreeView
              data={processTreeViewItems(tree, {
                select: select,
                expand: expand
              })}
              expandIcons={true}
              onItemClick={handleSelect}
              onExpandChange={handleSelect}
              item={NavItem}
            />
          </div>
        </div>
        <div className='k-drawer-content'>
          {isAuthenticated ? (
            props.children
          ) : (
            <div style={{ marginTop: '32px', display: 'block' }}>
              Please <SignInButton /> to view this page.
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(DRC);
