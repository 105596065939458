import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import { useIsMobile } from '../functions/useIsMobile';

export const Header = (props) => {
  const { onButtonClick, currentPage } = props;

  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const currentAccount = accounts[0] ? accounts[0] : null;
  const isMobile = useIsMobile();

  const styles =
    `
  .header{
    background-color: ` +
    process.env.REACT_APP_ENVIRONMENT_HEADER_COLOR +
    `
  }
  `;

  return (
    <header className='header'>
      <Helmet>
        <title>Woodfibre ReqGen - {currentPage}</title>
        <meta name='description' content='Woodfibre Requisition Generator' />
        <meta name='version' content={process.env.REACT_APP_VERSION} />
      </Helmet>
      <style>{styles}</style>
      <span
        style={{
          display: 'block',
          width: '100%',
          textAlign: 'center',
          fontWeight: 'bold',
          position: 'absolute',
          top: '-10px',
          fontSize: '80px',
          pointerEvents: 'none'
        }}
      >
        {process.env.REACT_APP_ENVIRONMENT}
      </span>
      <div className='nav-container'>
        <div className='menu-button' onClick={onButtonClick}>
          <span className={'k-icon k-i-menu'} />
        </div>

        {!isMobile && ( 
        <img
          src='/logo.png'
          alt='Woodfibre Logo'
          style={{ height: '80%', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />)}

        <div className='title'>
          {!isMobile && (<h1 onClick={() => navigate('/')}>Requisition Generator</h1>)}
          {isMobile && (<h3 onClick={() => navigate('/')}>Requisition Generator</h3>)}
        </div>

        <div className='user'>
          {isAuthenticated ? (
            <div className={'user-container'}>
              {!isMobile && ( <div style={{ marginRight: '16px' }}>{currentAccount ? currentAccount.name : 'Logged in'}</div> )}
              <SignOutButton />
            </div>
          ) : (
            <div style={{ float: 'right' }}>
              <SignInButton />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
